import React, { useEffect, useRef, useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sort from "../../components/elements/Sort"
import ProductGrid from "../../components/elements/ProductGrid"
import Pagination from "../../components/elements/Pagination"
import CategoryList from "../../components/elements/CategoryList"
import { API_BASE, PRODUCTS_PER_PAGE } from "../../spectory-config"
import { getProducts } from "../../functions/queries/products"
import ArchivePage from "."
import getAllProductCategories from "../../functions/queries/productCategories"

const ArchivePaginationPage = (props) => {
    return <ArchivePage  {...props} />
}

export default ArchivePaginationPage


export async function getServerData(context) {

    const productResponse = await getProducts(context);

    const categories = await getAllProductCategories();

    if (productResponse === false) {
        return {
            status: 404,
            headers: {},
            props: {}
        }
    }

    return {
        props: {
            products: productResponse.products,
            filters: productResponse.filters,
            categories: categories,
            pages: productResponse.pages,
            currentPage: parseInt(context.params.page),
            query: context.query,
            context: context
        }
    }

}